import React, { ReactNode } from 'react';
import { Editor } from '@tiptap/react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

import Dropdown from '@common/components/dropdown';
import { Button } from '@common/components/button';

type ToolbarHeadersDropdownType = {
  editor: Editor | null;
};

type Level = 1 | 2 | 3;
const HEADER_LEVELS: Level[] = [1, 2, 3];

const getCurrentStyleText = (editor: Editor | null, t: TFunction) => {
  if (!editor?.isActive('heading')) return t('knowledge_base:text');
  return t('knowledge_base:heading', { level: getCurrentLevel(editor) });
};

const getCurrentLevel = (editor: Editor | null): Level => HEADER_LEVELS.find((level) => {
  return editor?.isActive('heading', { level });
}) || 1;

const wrapper = (level: Level, children: ReactNode) => {
  switch (level) {
    case 1: return (<h1 className="tw-text-5xl tw-font-medium">{children}</h1>);
    case 2: return (<h2 className="tw-text-3xl tw-font-medium">{children}</h2>);
    case 3: return (<h3 className="tw-text-2xl tw-font-medium">{children}</h3>);
    default: return (<span>{children}</span>);
  }
};

const ToolbarHeadersDropdown = ({ editor }: ToolbarHeadersDropdownType) => {
  const { t } = useTranslation();
  return (
    <Dropdown
      toggle={(
        <Button className="!tw-bg-transparent hover:!tw-bg-auto" iconRight="expand_more">
          {getCurrentStyleText(editor, t)}
        </Button>
      )}
    >
      <Dropdown.Item
        key="text"
        disabled={!editor?.isActive('heading')}
        onClick={() => editor?.chain().focus().toggleHeading({ level: getCurrentLevel(editor) }).run()}
      >
        <span>Text</span>
      </Dropdown.Item>
      {HEADER_LEVELS.map((level) => (
        <Dropdown.Item
          key={level}
          disabled={editor?.isActive('heading', { level })}
          onClick={() => editor?.chain().focus().toggleHeading({ level }).run()}
        >
          {wrapper(level, t('knowledge_base:heading', { level }))}
        </Dropdown.Item>
      ))}
    </Dropdown>
  );
};

export default ToolbarHeadersDropdown;
