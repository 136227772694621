import React, { useCallback, useRef } from 'react';
import { BubbleMenu as BaseBubbleMenu, Editor, EditorStateSnapshot, useEditorState } from '@tiptap/react';
import { Instance, sticky } from 'tippy.js';

import createTempId from '@common/utils/create-temp-id';
import { getRenderContainer } from '../../utils';
import { SliderInput } from '../../../slider';

interface ImageMenuProps {
  editor: Editor | null;
  appendTo?: React.RefObject<any>
}

export const ImageMenu = ({ editor, appendTo }: ImageMenuProps) => {
  if (!editor) return null;
  const tippyInstance = useRef<Instance | null>(null);

  const getReferenceClientRect = useCallback(() => {
    const renderContainer = getRenderContainer(editor, 'node-image');
    const rect = renderContainer?.getBoundingClientRect() || new DOMRect(-1000, -1000, 0, 0);
    return rect;
  }, [editor]);

  const { width } = useEditorState({
    editor,
    selector: (ctx: EditorStateSnapshot<Editor>) => {
      return {
        width: parseInt(ctx.editor?.getAttributes('image')?.width || 0),
      };
    },
  });

  const handleChange = useCallback((value: number) => {
    editor?.chain().focus(undefined, { scrollIntoView: false }).setImageWidth(value).run();
  }, [editor]);

  return (
    <BaseBubbleMenu
      editor={editor}
      pluginKey={`imageBlockMenu-${createTempId()}`}
      updateDelay={0}
      shouldShow={() => editor?.isActive('image') || false}
      tippyOptions={{
        offset: [0, 8],
        zIndex: 1,
        popperOptions: {
          modifiers: [{ name: 'flip', enabled: false }],
        },
        getReferenceClientRect,
        onCreate: (instance: Instance) => {
          tippyInstance.current = instance;
        },
        appendTo: () => {
          return appendTo?.current;
        },
        plugins: [sticky],
        sticky: 'popper',
      }}
    >
      <div
        className={`
          tw-flex tw-flex-row tw-p-2 tw-items-center 
          tw-bg-white tw-rounded-lg tw-border tw-border-solid tw-border-neutral-200 tw-shadow-sm
        `}
      >
        <SliderInput value={width} onChange={handleChange} />
      </div>
    </BaseBubbleMenu>
  );
};
