import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@common/components/button';
import { TextareaInputHookForm } from '@common/components/form/inputs/textarea/textarea';

import { getCurrentOrgId } from '@modules/organisation/selectors/organisation';
import { useAppSelector } from '@common/hooks';
import Api from '@common/services/api';
import type { Folder } from '../../types';

type FolderDescriptionProps = {
  currentFolder: Folder;
  onUpdate?: () => void;
};

type FormValues = {
  description?: string;
};

const FolderDescription = ({ currentFolder, onUpdate }: FolderDescriptionProps) => {
  const { t } = useTranslation();
  const orgId = useAppSelector(getCurrentOrgId);

  const {
    control, setValue, formState, handleSubmit, reset,
  } = useForm<FormValues>({
    defaultValues: {
      description: currentFolder?.description,
    },
  });

  useEffect(() => {
    setValue('description', currentFolder?.description || '');
  }, [currentFolder?.description]);

  const onSubmit = handleSubmit(async (data: FormValues) => {
    onUpdate?.();
    await Api.put(`/v1/organisations/${orgId}/documents/${currentFolder.id}`, {
      description: data.description || null,
    });
    reset(data);
  });

  return (
    <div className="tw-flex">
      <TextareaInputHookForm
        className="!tw-border-none tw-mr-6 !tw-px-0 !tw-text-zinc-600 tw-leading-6"
        name="description"
        placeholder={t('knowledge_base:folder_description_placeholder')}
        control={control}
      />
      <div className="tw-absolute tw-right-4 tw-bottom-4">
        <Button
          disabled={!formState.isDirty}
          isLoading={formState.isSubmitting}
          type="primary"
          onClick={onSubmit}
        >
          {t('common:save')}
        </Button>
      </div>
    </div>
  );
};

export default FolderDescription;
