import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { EMPTY_CONTENT, EditorInputHookForm } from '@common/components/form/inputs/editor';
import { SelectInputHookForm } from '@common/components/form/inputs/select';
import { Button } from '@common/components/button';
import Icon from '@common/components/icon';
import { Container } from '@common/components/container';
import { TopNavigationBar } from '@common/components/navigation-bar';
import PageLeaveCheckPopup from '@common/components/confirm-button/page-leave-check-popup';
import PageForm from '@modules/documents/forms/page';
import Spinner from '@common/components/spinner';
import * as Alert from '@common/services/alert';

import { Api } from '@common/services/api';
import { useAppSelector } from '@common/hooks/redux';
import { getCurrentOrgId } from '@modules/organisation/selectors/organisation';
import { useDocuments } from '@modules/documents/context';
import { useCurrentFolder } from '@modules/documents/hooks/use-current-folder';
import { useBreadcrumbs } from '@modules/documents/hooks/use-breadcrumbs';
import { PageFormValues } from '@modules/documents/forms/page/page';
import { EStatus } from '@common/definitions';
import { DocumentsRouteParams, ResponsePage } from '@modules/documents/types';

export type EditPageFormValues = {
  name: string;
  icon: string;
  status: EStatus;
  content?: {
    type: string;
    content: any[];
  };
};

interface PageDetailProps {
  basePath: string;
}

const PageDetail = ({ basePath }: PageDetailProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const documentsContext = useDocuments();
  const currentFolder = useCurrentFolder();
  const breadcrumbs = useBreadcrumbs({ currentFolder, ...documentsContext, basePath });
  const { pageId, folderId, networkId } = useParams<DocumentsRouteParams>();
  const organisationId = useAppSelector(getCurrentOrgId);
  const isNew = pageId === 'new';
  const [showPageFormModal, setShowPageFormModal] = useState(false);
  const [loading, setLoading] = useState(!isNew);
  const path = generatePath(basePath, { networkId });

  useEffect(() => window.scrollTo(0, 0), []);

  const statusOptions = useMemo(() => {
    return [{
      value: EStatus.DRAFT,
      label: t('common:status_draft')
    }, {
      value: EStatus.LIVE,
      label: t('common:status_live')
    }];
  }, [t]);

  const {
    control, setValue, watch, reset, handleSubmit, formState,
  } = useForm<EditPageFormValues>({
    mode: 'all',
    defaultValues: async () => {
      if (isNew) {
        return {
          status: EStatus.DRAFT,
          name: '',
          icon: '',
          content: EMPTY_CONTENT,
        };
      }
      const res = await Api.post<{
        data: any;
      }>(`/v1/organisations/${organisationId}/documents/${pageId}/open?page_format=json`);
      setLoading(false);
      return {
        status: res.data.status,
        name: res.data.name,
        icon: res.data.page.icon,
        content: isEmpty(res.data.page.contents) ? EMPTY_CONTENT : res.data.page.contents,
      };
    }
  });

  const values = watch();
  const { isSubmitting, isDirty } = formState;

  const onSave = handleSubmit(async (newValues) => {
    try {
      const endpoint = `/v1/organisations/${organisationId}/documents/page`;
      const payload = {
        status: newValues.status,
        name: newValues.name || null,
        page: {
          icon: newValues.icon || null,
          contents: newValues.content || {},
        },
      };
      if (isNew) {
        // Fix me: add typing for API data response
        const response = await Api.post<{ data: ResponsePage }>(endpoint, {
          ...payload,
          parent_folder_id: folderId || null,
        });
        Alert.success(t('knowledge_base:page_save_success'));
        reset(newValues);
        history.replace(
          `${path}/${folderId ? `${folderId}/` : ''}page/${response.data.id}`,
          { dontAskForPageLeaveConfirmation: true }
        );
      } else {
        await Api.put(`${endpoint}/${pageId}`, payload);
        Alert.success(t('knowledge_base:page_save_success'));
        reset(newValues);
      }
    } catch (response: any) {
      Alert.error(t('knowledge_base:page_update_error'));
    }
  });

  const onUpdate = (newPage: PageFormValues) => {
    setValue('name', newPage.name, { shouldDirty: true });
    setValue('icon', newPage.icon?.native, { shouldDirty: true });
    setShowPageFormModal(false);
  };

  return (
    <Container name="Documents">
      <PageLeaveCheckPopup
        message={t('knowledge_base:page_leave_check_popup_message')}
        shouldRunOnRoute={() => isDirty}
        onSave={onSave}
      />
      <TopNavigationBar
        breadcrumbs={breadcrumbs}
        form={PageForm}
        badge={loading ? undefined : values.status}
        formProps={{
          name: values.name,
          icon: values.icon,
          onUpdate,
          show: showPageFormModal,
          onClose: () => setShowPageFormModal(false),
          onShow: () => setShowPageFormModal(true),
        }}
        title={loading ? <Spinner /> : (
          <div className="tw-flex tw-items-center tw-gap-4">
            {(values.icon && <span>{values.icon}</span>) || <Icon className="tw-text-zinc-400" type="insert_emoticon" />}
            <div className="tw-max-w-[600px] tw-truncate" title={values.name}>
              {values.name || <span className="tw-text-zinc-400">{t('documents:placeholder_title')}</span>}
            </div>
          </div>
        )}
        action={loading ? undefined : (
          <>
            <SelectInputHookForm
              control={control}
              name="status"
              clearable={false}
              className="tw-mr-4 !tw-min-w-[150px]"
              options={statusOptions}
            />
            <Button
              size="large"
              onClick={() => history.push(`${path}${folderId ? `/${folderId}` : ''}`)}
            >
              {t('common:back')}
            </Button>
            <Button
              type="primary"
              size="large"
              isLoading={isSubmitting}
              onClick={onSave}
              disabled={!isDirty && !isNew}
            >
              {t('common:save')}
            </Button>
          </>
        )}
      />
      <div>
        {loading ? <Spinner centered /> : (
          <EditorInputHookForm
            sticky
            name="content"
            control={control}
            className="tw-min-h-screen"
            Wrapper={Container.Content}
            onPrint={() => history.push(`${path}/page/${pageId}/print`)}
          />
        )}
      </div>
    </Container>
  );
};

export default PageDetail;
