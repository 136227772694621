import React from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';

import Dropdown from '@common/components/dropdown';
import { Button } from '@common/components/button';
import { TableDropdown } from '@common/components/table';

import { FileUploadForm } from '@modules/documents/forms/file-upload';
import FolderModalForm from '@modules/documents/forms/folder-modal-form';

import type { DocumentsRouteParams, Folder, Document } from '@modules/documents/types';

type DocumentsActionsProps = {
  currentFolder?: Folder;
  userId?: string;
  onUpdate: (document: Document) => void;
};

const DocumentsActions = ({ currentFolder, userId, onUpdate }: DocumentsActionsProps) => {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const history = useHistory();
  const { folderId } = useParams<DocumentsRouteParams>();
  const isPersonalFolder = !!currentFolder?.personal_root_user_id || folderId === 'personal';

  return (
    <Dropdown
      alignRight
      toggle={(
        <Button type="primary" icon="add" size="large">
          {t('documents:new')}
        </Button>
      )}
    >
      <FolderModalForm
        userId={isPersonalFolder ? userId : undefined}
        onUpdate={onUpdate}
        currentFolder={currentFolder}
      >
        <TableDropdown.Item icon="folder">
          {t('documents:folder')}
        </TableDropdown.Item>
      </FolderModalForm>
      <FileUploadForm
        parentFolderId={folderId}
        userId={isPersonalFolder ? userId : undefined}
        personalFolder={isPersonalFolder}
        onUpdate={onUpdate}
      >
        <TableDropdown.Item icon="upload">
          {t('documents:upload')}
        </TableDropdown.Item>
      </FileUploadForm>
      {!isPersonalFolder && (
        <TableDropdown.Item icon="document" onClick={() => history.push(`${url}/page/new`)}>
          {t('documents:page')}
        </TableDropdown.Item>
      )}
    </Dropdown>
  );
};

export default DocumentsActions;
