import React, { useMemo } from 'react';
import { Node } from '@tiptap/pm/model';
import { NodeViewWrapper, ReactNodeViewContext } from '@tiptap/react';

interface ImageBlockViewProps {
  node: Node;
}

export const ImageView = ({ node }: ImageBlockViewProps) => {
  // Setting the drag behaviour to null fixes the drag behaviour
  // because it falls back to the default behaviour
  const value = useMemo(() => ({ onDragStart: () => {} }), []);

  return (
    <ReactNodeViewContext.Provider value={value}>
      <NodeViewWrapper>
        <div style={{ width: node.attrs.width }}>
          <img src={node.attrs.src} alt="" data-drag-handle />
        </div>
      </NodeViewWrapper>
    </ReactNodeViewContext.Provider>
  );
};
