import React from 'react';
import { Editor } from '@tiptap/react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Modal } from '@common/components/modal';
import { Button } from '@common/components/button';
import { Group, Row } from '@common/components/form';
import { TextInputHookForm } from '@common/components/form/inputs/text';
import { ToggleHookForm } from '@common/components/form/inputs/toggle';
import { regex } from '@common/utils/validation';

type ToolbarLinkModalType = {
  text: string;
  editor: Editor | null;
  onClose: () => void;
};

type FormPayload = {
  text: string;
  url: string;
  newTab: boolean;
};

const ToolbarLinkModal = ({
  text, editor, onClose,
}: ToolbarLinkModalType) => {
  const { t } = useTranslation();
  const previousUrl = editor?.getAttributes('link').href;
  const previousTarget = editor?.getAttributes('link').target;
  const { control, handleSubmit, formState } = useForm<FormPayload>({
    mode: 'all',
    defaultValues: {
      text,
      url: previousUrl || '',
      newTab: previousTarget !== '',
    }
  });

  const onAddLink = handleSubmit(({ url, newTab }) => {
    editor?.chain().focus().extendMarkRange('link').setLink({
      href: url,
      target: newTab ? '_blank' : ''
    })
    .run();
    onClose();
  });

  return (
    <Modal
      show
      size="small"
      onClose={onClose}
      title={t('knowledge_base:add_link')}
      content={(
        <Group>
          <Row>
            <Group>
              <TextInputHookForm
                control={control}
                disabled
                label={t('knowledge_base:link_text')}
                name="text"
              />
            </Group>
          </Row>
          <Row>
            <Group>
              <TextInputHookForm
                control={control}
                label={t('knowledge_base:link_url')}
                name="url"
                rules={{ required: true, pattern: regex.URL }}
              />
            </Group>
          </Row>
          <Row>
            <Group>
              <ToggleHookForm
                control={control}
                name="newTab"
                label={t('knowledge_base:link_target')}
              />
            </Group>
          </Row>
        </Group>
      )}
      footer={(
        <Button
          type="primary"
          disabled={!formState.isValid}
          onClick={onAddLink}
        >
          {t('knowledge_base:add_link')}
        </Button>
      )}
    />
  );
};

export default ToolbarLinkModal;
