import React, { memo, useCallback, useState } from 'react';
import { BubbleMenu as BaseBubbleMenu, Editor } from '@tiptap/react';
import { useTranslation } from 'react-i18next';

import { isColumnGripSelected } from '../../extensions/table/utils';
import Dropdown from '@common/components/dropdown/dropdown';

interface MenuProps {
  editor: Editor | null;
  appendTo?: React.RefObject<any>
}

export const TableColumnMenu = memo(({ editor, appendTo }: MenuProps): JSX.Element => {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const shouldShow = useCallback(
    ({ view, state, from }) => {
      if (!state || !from || !editor) {
        setShow(false);
        return false;
      }
      const isSelected = isColumnGripSelected({ editor, view, state, from });
      setShow(isSelected);
      return isSelected;
    },
    [editor],
  );

  const onAddColumnBefore = useCallback(() => {
    editor?.chain().focus().addColumnBefore().run();
  }, [editor]);

  const onAddColumnAfter = useCallback(() => {
    editor?.chain().focus().addColumnAfter().run();
  }, [editor]);

  const onDeleteColumn = useCallback(() => {
    editor?.chain().focus().deleteColumn().run();
  }, [editor]);

  return (
    <BaseBubbleMenu
      editor={editor}
      pluginKey="tableColumnMenu"
      updateDelay={0}
      shouldShow={shouldShow}
      tippyOptions={{
        zIndex: 4,
        appendTo: () => {
          return appendTo?.current;
        },
      }}
    >
      {show && (
        <div>
          <Dropdown open>
            <Dropdown.Item icon="arrow_left" onClick={onAddColumnBefore}>
              <span>{t('knowledge_base:add_column_before')}</span>
            </Dropdown.Item>
            <Dropdown.Item icon="arrow_right" onClick={onAddColumnAfter}>
              <span>{t('knowledge_base:add_column_after')}</span>
            </Dropdown.Item>
            <Dropdown.Item icon="delete" onClick={onDeleteColumn} danger>
              <span>{t('knowledge_base:delete_column')}</span>
            </Dropdown.Item>
          </Dropdown>
        </div>
      )}
    </BaseBubbleMenu>
  );
});
