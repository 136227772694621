import React, { forwardRef, useEffect, useRef, useState } from 'react';

import { EmojiPicker } from '../emoji';
import TextareaInput, { TextAreaInputProps } from './textarea-input';

export type TextareaInputOwnProps = Omit<JSX.IntrinsicElements['textarea'], 'ref' | 'onChange'> &
  Omit<TextAreaInputProps, 'ref' | 'onChange'> & {
  value?: string;
  placeholder?: string;
  rows?: boolean | number;
  enableEmojis?: boolean,
  onChange?: any;
};

const TextareaEmojiInput = forwardRef<HTMLTextAreaElement, TextareaInputOwnProps>(({
  value = '',
  placeholder,
  rows,
  onChange,
  className = '',
  ...props
}, ref) => {
  const [, setPlaceholder] = useState<string | null>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (placeholder) setPlaceholder(placeholder);
  }, []);

  return (
    <div className="TextareaInput__Container">
      <EmojiPicker
        onSelect={(emoji) => {
          const el = (((typeof ref !== 'function') && ref?.current) || inputRef.current);
          if (!el) return;
          try {
            // Split string at selection
            const initialStart = el.selectionStart;
            const startText = value.substring(0, initialStart);
            const endText = value.substring(el.selectionEnd, value.length);

            onChange(`${startText}${emoji.native}${endText}`);

            setTimeout(() => {
              // Set new focus so you can instantly add a new emoji after the previous one
              el.focus();
              el.setSelectionRange(initialStart + 2, initialStart + 2);
            }, 0);
          } catch (e) {
            onChange(`${value}${emoji.native}`);
          }
        }}
      />
      <TextareaInput
        {...props}
        ref={ref || inputRef}
        value={value}
        className={`TextareaInput--emoji ${className}`}
        placeholder={placeholder}
        rows={rows}
        onChange={onChange}
      />
    </div>
  );
});

export default TextareaEmojiInput;
