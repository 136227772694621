import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory, useParams } from 'react-router-dom';

import { Api } from '@common/services/api';
import { useAppSelector } from '@common/hooks/redux';
import { getCurrentOrgId } from '@modules/organisation/selectors/organisation';
import { Container } from '@common/components/container';
import { TopNavigationBar } from '@common/components/navigation-bar';
import { Button } from '@common/components/button';
import EditorInput from '@common/components/form/inputs/editor/editor-input';
import { useCurrentFolder } from '@modules/documents/hooks/use-current-folder';
import { useBreadcrumbs } from '@modules/documents/hooks/use-breadcrumbs';
import { useDocuments } from '@modules/documents/context';

interface PageShowProps {
  basePath: string;
}

const PageShow = ({ basePath }: PageShowProps) => {
  const history = useHistory();
  const { t } = useTranslation();
  const organisationId = useAppSelector(getCurrentOrgId);
  const { pageId, folderId, networkId } = useParams<{ pageId: string, folderId?: string, networkId: string }>();
  const [data, setData] = useState<any>(null);
  const currentFolder = useCurrentFolder();
  const documentsContext = useDocuments();
  const breadcrumbs = useBreadcrumbs({ currentFolder, ...documentsContext, basePath });
  const path = generatePath(basePath, { networkId });
  useEffect(() => window.scrollTo(0, 0), []);

  useEffect(() => {
    const fetchPage = async () => {
      // Fix me: add typing for API data response
      const res = await Api.post<{
        data: any;
      }>(`/v1/organisations/${organisationId}/documents/${pageId}/open?page_format=json`);
      setData(res.data);
    };
    fetchPage();
  }, [pageId]);

  return (
    <Container name="Documents">
      <TopNavigationBar
        breadcrumbs={breadcrumbs}
        badge={data?.page.status}
        title={(
          <div className="tw-flex tw-items-center tw-gap-4">
            <span>{data?.page.icon}</span>
            {data?.name}
          </div>
        )}
        action={(
          <Button
            size="large"
            onClick={() => history.push(`${path}${folderId ? `/${folderId}` : ''}`)}
          >
            {t('forms:go_back')}
          </Button>
        )}
      />
      <div>
        <EditorInput
          sticky
          className="tw-min-h-screen"
          value={data?.page.contents}
          Wrapper={Container.Content}
          onPrint={() => history.push(`${path}/page/${pageId}/print`)}
        />
      </div>
    </Container>
  );
};

export default PageShow;
