import React from 'react';
import { combineClassNames } from '@common/utils/combineClassNames';

type TableOfContentsItemProps = {
  level: number;
  itemIndex: number;
  id: string;
  textContent: string;
  index: number;
  onItemClick: (e: React.MouseEvent<HTMLAnchorElement>, id: string) => void;
};

const TableOfContentsItem = ({
  level, itemIndex, id, textContent, index, onItemClick,
}: TableOfContentsItemProps) => {
  return (
    <div
      key={id}
      className={combineClassNames('tw-pb-1', {
        'tw-ml-5 tw-pl-2 tw-border-solid tw-border-0 tw-border-l tw-border-zinc-200': level > 1,
      })}
    >
      <a
        href={`#${id}`}
        onClick={(e) => onItemClick(e, id)}
        data-item-index={itemIndex}
        className={combineClassNames(
          {
            'tw-mt-4': !!(level === 1 && index),
            'tw-pl-6': level === 3,
          },
          'tw-block tw-py-2 tw-pl-2',
          'tw-text-base tw-font-medium tw-leading-6',
          'tw-text-zinc-500 hover:tw-text-blue-600 hover:tw-bg-zinc-50',
        )}
      >
        {textContent}
      </a>
    </div>
  );
};

export default TableOfContentsItem;
