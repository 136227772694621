import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { combineClassNames } from '@common/utils/combineClassNames';

type TextReadMoreProps = {
  description?: string;
  className?: string;
};

const checkOverflow = (textContainer: HTMLSpanElement | null): boolean => {
  if (textContainer)
    return (
      textContainer.offsetHeight < textContainer.scrollHeight || textContainer.offsetWidth < textContainer.scrollWidth
    );
  return false;
};

const TextReadMore = ({ description, className }: TextReadMoreProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [enableReadMore, setEnableReadMore] = useState(false);
  const paragraphRef = useRef<HTMLParagraphElement | null>(null);

  useEffect(() => {
    if (checkOverflow(paragraphRef.current)) setEnableReadMore(true);
    else setEnableReadMore(false);
  }, [description]);

  return (
    <>
      <p
        ref={paragraphRef}
        className={combineClassNames(
          `tw-whitespace-pre-wrap${className ? ` ${className}` : ''}`, {
            'tw-max-h-[42px] tw-overflow-y-hidden': !open,
          },
        )}
      >
        {description}
      </p>
      {enableReadMore && !open && (
        <a className="tw-underline" onClick={() => setOpen(true)}>
          {t('common:read_more')}
        </a>
      )}
      {enableReadMore && open && (
        <a className="tw-underline" onClick={() => setOpen(false)}>
          {t('common:read_less')}
        </a>
      )}
    </>
  );
};

export default TextReadMore;
