import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { useAppSelector, useSearchParams } from '@common/hooks';
import { useFolderDocuments } from '@modules/documents/hooks/use-folder-documents';
import { useCurrentFolder } from '@modules/documents/hooks/use-current-folder';
import { useBreadcrumbs } from '@modules/documents/hooks/use-breadcrumbs';
import { useDocuments } from '@modules/documents/context';
import { selected } from '@modules/core/selectors/logged-user';

import { SearchBar } from '@common/components/search-bar';
import DocumentsActions from '@modules/documents/components/documents-actions';
import { TopNavigationBar } from '@common/components/navigation-bar';
import Container from '@common/components/container';
import TextReadMore from '@common/components/text-read-more';
import FolderDescription from '@modules/documents/forms/folder-description';
import DocumentsBrowser from '../../components/documents-browser';

import { DocumentsRouteParams } from '@modules/documents/types';
import { useFolderPermissions } from '@modules/documents/hooks/use-folder-permissions';

interface FolderDetailProps {
  basePath: string;
}

const FolderDetail = memo(({ basePath }: FolderDetailProps) => {
  const { t } = useTranslation();
  const { folderId, filter } = useParams<DocumentsRouteParams>();
  const documentsContext = useDocuments();
  const loggedUserId = useAppSelector(selected).id;
  const { searchParams, setSearchParam } = useSearchParams<{ search?: string }>();
  const currentFolder = useCurrentFolder();
  const { canCreate, canUpdate } = useFolderPermissions();
  const folderDocuments = useFolderDocuments({
    ...documentsContext, searchParams, loggedUserId, filter, folderId,
  });
  const breadcrumbs = useBreadcrumbs({
    currentFolder, ...documentsContext, onClear: folderDocuments.onClear, basePath,
  });

  const isPersonalFolder = currentFolder?.personal_root_user_id || folderId === 'personal';
  const shouldShowFolderDescription = currentFolder && !isPersonalFolder &&
    (canUpdate || (!canUpdate && currentFolder?.description));

  return (
    <Container name="Documents">
      <TopNavigationBar
        breadcrumbs={breadcrumbs}
        title={t('documents:filter', { context: searchParams.search ? 'search' : filter || 'all' })}
        action={!filter && (
          <>
            <SearchBar
              key={`${filter}${folderId}`} // remounting when those change in order to clear the input
              defaultValue={searchParams.search}
              placeholder={t('documents:search_placeholder')}
              onSearch={(newSearchTerm: Parameters<typeof setSearchParam>[1]) => {
                setSearchParam('search', newSearchTerm, basePath);
              }}
            />
            {!searchParams.search && canCreate && (
              <DocumentsActions
                currentFolder={currentFolder}
                onUpdate={folderDocuments.onUpdate}
                userId={loggedUserId}
              />
            )}
          </>
        )}
      />
      <Container.Content>
        {shouldShowFolderDescription && (
          <div className="tw-relative tw-mb-6 tw-p-4 tw-bg-white tw-rounded-lg tw-min-h-[112px]">
            <h4 className="tw-text-zinc-800 tw-mb-1">
              {t('knowledge_base:folder_description')}
            </h4>
            {canUpdate && (
              <FolderDescription
                currentFolder={currentFolder}
                onUpdate={folderDocuments.invalidate}
              />
            )}
            {!canUpdate && (
              <TextReadMore
                className="tw-text-zinc-600 tw-leading-6"
                description={currentFolder.description}
              />
            )}
          </div>
        )}
        <DocumentsBrowser
          getFolderPath={(newFolderId: string) => `${basePath}/${newFolderId}`}
          userId={isPersonalFolder ? loggedUserId : undefined}
          basePath={basePath}
          {...folderDocuments}
        />
      </Container.Content>
    </Container>
  );
});

export default FolderDetail;
