import Image from '@tiptap/extension-image';
import { ReactNodeViewRenderer } from '@tiptap/react';
import { ImageView } from '../../components/image/image-view';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    imageBlock: {
      setImageWidth: (width: number) => ReturnType
    }
  }
}

export const CustomImage = Image.extend({
  name: 'image',

  addAttributes() {
    return {
      ...this.parent?.(),
      'data-id': {
        default: null,
      },
      width: {
        default: '60%',
        parseHTML: (element) => element.getAttribute('data-width'),
        renderHTML: (attributes) => ({
          'data-width': attributes.width,
        }),
      },
      'data-type': {
        default: 'image',
      },
      poster: {
        default: null,
      },
    };
  },

  addCommands() {
    return {
      setImageWidth:
        (width: number) => ({ commands }: { commands: any }) => {
          return commands.updateAttributes('image', { width: `${Math.max(0, Math.min(100, width))}%` });
        },
    };
  },

  addNodeView() {
    // eslint-disable-next-line new-cap
    return ReactNodeViewRenderer(ImageView);
  },
});

