import { useTranslation } from 'react-i18next';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { EditorContent, useEditor } from '@tiptap/react';

// Extensions
import Bold from '@tiptap/extension-bold';
import BulletList from '@tiptap/extension-bullet-list';
import Document from '@tiptap/extension-document';
import Dropcursor from '@tiptap/extension-dropcursor';
import Gapcursor from '@tiptap/extension-gapcursor';
import Italic from '@tiptap/extension-italic';
import Heading from '@tiptap/extension-heading';
import ListItem from '@tiptap/extension-list-item';
import Link from '@tiptap/extension-link';
import OrderedList from '@tiptap/extension-ordered-list';
import Paragraph from '@tiptap/extension-paragraph';
import Placeholder from '@tiptap/extension-placeholder';
import Strike from '@tiptap/extension-strike';
import TableRow from '@tiptap/extension-table-row';
import Text from '@tiptap/extension-text';
import Underline from '@tiptap/extension-underline';
import History from '@tiptap/extension-history';
import { TableOfContents as TableOfContentsExtension, getHierarchicalIndexes } from '@tiptap-pro/extension-table-of-contents';

// Custom extensions
import Table from './extensions/table/table';
import { TableCell } from './extensions/table/cell';
import { TableHeader } from './extensions/table/header';
import { Video } from './extensions/files/video';
import { CustomImage } from './extensions/files/image';

// Components
import { TableColumnMenu } from './components/table/table-column-menu';
import { TableRowMenu } from './components/table/table-row-menu';
import { ImageMenu } from './components/image/image-menu';
import Toolbar from './components/toolbar';
import TableOfContents, { TableOfContentsItemType } from './components/table-of-contents';

// Utils, Hooks
import { combineClassNames } from '@common/utils/combineClassNames';
import { useDidComponentMount } from '@common/hooks/use-did-component-mount';

import './editor.scss';

export type EditorInputProps = {
  sticky?: boolean;
  value?: any;
  className?: string;
  onChange?: (value: any) => void;
  Wrapper?: React.ComponentType<{ children: React.ReactNode, className?: string }> | string;
  onPrint?: (html: string) => void;
};

export const EMPTY_CONTENT = {
  type: 'doc',
  content: [{ type: 'paragraph' }],
};

const isSame = (a: any, b: any) => JSON.stringify(a) === JSON.stringify(b);

const EditorInput = ({
  sticky, value, className, Wrapper = 'div', onChange, onPrint,
}: EditorInputProps) => {
  const { t } = useTranslation();
  const [items, setItems] = useState<TableOfContentsItemType[]>([]);
  const [sidebarActive, setSidebarActive] = useState(true);
  const didMount = useDidComponentMount();

  const extensions = useMemo(() => [
    Dropcursor, Gapcursor, History,
    Document, Paragraph, Text, Video, CustomImage,
    Bold, Italic, Underline, Strike,
    BulletList, OrderedList, ListItem,
    Table, TableRow, TableHeader, TableCell,
    TableOfContentsExtension.configure({
      getIndex: getHierarchicalIndexes,
      onUpdate(content) {
        if (didMount) setItems(content);
      },
    }),
    Heading.configure({
      levels: [1, 2, 3],
    }),
    Link.configure({
      openOnClick: false,
      autolink: true,
      defaultProtocol: 'https',
    }),
    Placeholder.configure({
      placeholder: t('knowledge_base:editor_placeholder'),
    }),
  ], [t]);

  const editor = useEditor({
    extensions,
    editable: !!onChange,
    onUpdate: () => {
      setContent(editor?.getJSON());
    },
  });
  const menuContainerRef = useRef(null);
  const [content, setContent] = useState<any>(value);

  useEffect(() => {
    if (!isSame(content, value)) {
      // Initialize editor with content
      setContent(value);
      editor?.chain().setMeta('addToHistory', false).setContent(value).run();
    }
  }, [value]);

  useEffect(() => onChange?.(content), [content]);

  return (
    <div className="tw-h-full" ref={menuContainerRef}>
      <Toolbar
        editor={editor}
        sticky={sticky}
        editable={!!onChange}
        onPrint={onPrint}
        sidebarActive={sidebarActive}
        hideActions={items.length > 0 ? [] : ['sidebar']}
        setSidebarActive={setSidebarActive}
      />
      <Wrapper className="tw-flex !tw-flex-row">
        {onChange && <TableRowMenu editor={editor} appendTo={menuContainerRef} />}
        {onChange && <TableColumnMenu editor={editor} appendTo={menuContainerRef} />}
        {onChange && <ImageMenu editor={editor} appendTo={menuContainerRef} />}
        <EditorContent
          editor={editor}
          className={combineClassNames(className,
            'tw-flex tw-grow tw-h-full tw-overflow-y-auto tw-box-border tw-p-2',
            'tw-rounded-lg tw-shadow-sm tw-bg-white',
          )}
        />
        {items.length > 0 && sidebarActive && (
          <TableOfContents
            className="tw-w-[310px] tw-ml-4 tw-box-border tw-flex-shrink-0"
            editor={editor}
            items={items}
          />
        )}
      </Wrapper>
    </div>
  );
};

export default EditorInput;
