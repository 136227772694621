import React from 'react';

import { Control, FieldValues, UseControllerProps, useController } from 'react-hook-form';
import EditorInput, { EditorInputProps } from './editor-input';

/**
 * By default the form validation mode is set to `mode: 'onSubmit'`.
 * This means the validation will be shown after submitting the form.
 * You probably want to set this option to `all` to have immediate feedback when a field does not pass the added rules.
 * useForm({ mode: 'all' });
 */
export function EditorInputHookForm<T extends FieldValues>(
  props: EditorInputProps & UseControllerProps<T> & { label?: string, control: Control<T> },
) {
  const { field } = useController(props);
  return (
    <EditorInput
      {...props}
      {...field}
      onChange={props.onChange || field.onChange}
    />
  );
}
