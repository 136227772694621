import React, { useCallback } from 'react';

interface SliderInputProps {
  value: number;
  onChange: (value: number) => void;
  min?: number;
  max?: number;
  step?: number;
}

export const SliderInput = ({
  value, onChange, min = 20, max = 100, step = 10,
}: SliderInputProps) => {
  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(parseInt(e.target.value));
  }, [onChange]);

  return (
    <div className="tw-flex tw-items-center tw-gap-2">
      <input
        className="tw-h-2 tw-bg-neutral-200 tw-border-0 tw-rounded tw-appearance-none tw-fill-neutral-300"
        type="range"
        min={min}
        max={max}
        step={step}
        onChange={handleChange}
        value={value}
      />
      <span className="tw-text-sm tw-font-semibold tw-text-neutral-500 tw-select-none">{value}%</span>
    </div>
  );
};
