import React from 'react';
import { Editor } from '@tiptap/react';
import { useTranslation } from 'react-i18next';

import { Modal } from '@common/components/modal';
import { Group, Row } from '@common/components/form';
import { Button } from '@common/components/button';
import { Attachment, FileInProgress } from '@common/types/objects';
import FormAttachments from '@common/components/form/attachments/attachments';
import { isProcessingAttachments } from '@common/utils/file';

import { FileInput } from '../../../file';
import { useChangeFileInput } from '../../../file/hooks';
import { IMAGES_ACCEPT, VIDEO_ACCEPT } from '../../../file/utils';

export enum EditorFileType {
  VIDEO = 'video',
  IMAGE = 'image',
}

type ToolbarFileModalType = {
  type: `${EditorFileType}`;
  editor: Editor | null;
  onClose: () => void;
};

const MAX_AMOUNT_OF_FILES = 4;

const ToolbarFileModal = ({
  type, editor, onClose,
}: ToolbarFileModalType) => {
  const { t } = useTranslation();
  const { files, onRemove, onChange } = useChangeFileInput();

  const onAddFiles = () => {
    files.forEach((file) => {
      setTimeout(() => {
        editor?.chain().insertContentAt(editor?.state.selection.anchor, {
          type,
          attrs: {
            'data-id': file.id,
            src: (file as Attachment).path,
          },
        }).focus().run();
      });
    });
    onClose();
  };

  return (
    <Modal
      show
      size="small"
      onClose={onClose}
      title={type === EditorFileType.VIDEO ? t('knowledge_base:add_video') : t('knowledge_base:add_image')}
      content={(
        <Group>
          <Row>
            <Group>
              <FileInput
                accept={type === EditorFileType.IMAGE ? IMAGES_ACCEPT : VIDEO_ACCEPT}
                multiple={MAX_AMOUNT_OF_FILES}
                maxFileSize={(type === EditorFileType.VIDEO && 500) || undefined}
                value={files}
                onChange={onChange}
              />
            </Group>
          </Row>
          <Row>
            <Group>
              <FormAttachments
                fields={files as (Attachment & FileInProgress)[]}
                onRemove={onRemove}
              />
            </Group>
          </Row>
        </Group>
      )}
      footer={(
        <Button
          type="primary"
          disabled={files?.length === 0 || isProcessingAttachments(files)}
          onClick={onAddFiles}
        >
          {type === EditorFileType.VIDEO ? t('knowledge_base:add_video') : t('knowledge_base:add_image')}
        </Button>
      )}
    />
  );
};

export default ToolbarFileModal;
