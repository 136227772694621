import { Node } from '@tiptap/react';

export const Video = Node.create({
  name: 'video',
  group: 'block',
  addAttributes() {
    return {
      src: {
        default: null,
        parseHTML: (el) => (el as HTMLSpanElement).getAttribute('src'),
        renderHTML: (attrs) => ({ src: attrs.src }),
      },
      'data-id': {
        default: null,
      },
      poster: {
        default: null,
        // Hide poster
        renderHTML: () => ({
          poster: ''
        }),
      },
    };
  },
  parseHTML() {
    return [
      {
        tag: 'video',
        getAttrs: (el) => ({ src: (el as HTMLVideoElement).getAttribute('src') }),
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return [
      'video',
      { controls: 'true', ...HTMLAttributes },
      ['source', HTMLAttributes]
    ];
  },
});
