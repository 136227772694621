import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Route, Switch, matchPath, useParams, useRouteMatch,
} from 'react-router';

import { SubNavigationBar } from '@common/components/navigation-bar';
import RouteNotFound from '@common/components/route-not-found';
import PageDetail from '../page-detail';
import FolderDetail from '../folder-detail';
import PageShow from '../page-show';
import { useSearchParams } from '@common/hooks/use-search-params';
import { Filters } from '../../constants';
import { EPermissions } from '@common/definitions';
import { usePermission } from '@common/hooks';
import { DocumentsProvider } from '@modules/documents/context';
import { DocumentsRouteParams } from '@modules/documents/types';

export const DocumentsContainer = () => {
  const { t } = useTranslation();
  const { networkId } = useParams<DocumentsRouteParams>();
  const { searchParams } = useSearchParams<{ search?: string }>();
  const { path } = useRouteMatch();
  const canCreatePages = usePermission([EPermissions.NETWORK_DOCUMENTS_CREATE, EPermissions.ORGANISATION_DOCUMENTS_CREATE]);
  return (
    <DocumentsProvider>
      <div className="DocumentsContainer">
        <SubNavigationBar title={networkId ? t('core:tab_documents') : t('core:tab_documents_admin')}>
          <SubNavigationBar.Item
            exact
            icon="folder"
            path={path}
            title={t('documents:filter_all')}
            isActive={(_, { pathname }) => !searchParams.search && !!matchPath(pathname, {
              path: [path, `${path}/:folderId`, `${path}/page/:pageId`, `${path}/:folderId/page/:pageId`],
              exact: true,
            })}
          />
          <SubNavigationBar.Item
            icon="access_time"
            path={`${path}/filter/${Filters.RECENT}`}
            title={t('documents:filter_recents')}
          />
          <SubNavigationBar.Item
            icon="star"
            path={`${path}/filter/${Filters.FAVORITES}`}
            title={t('documents:filter_favorites')}
          />
          <SubNavigationBar.Item
            icon="delete"
            path={`${path}/filter/${Filters.TRASH}`}
            title={t('documents:filter_trash')}
          />
        </SubNavigationBar>
        <Switch>
          <Route
            exact
            path={[path, `${path}/:folderId`, `${path}/filter/:filter`]}
            component={() => <FolderDetail basePath={path} />}
          />
          <Route
            exact
            path={[`${path}/page/:pageId`, `${path}/:folderId/page/:pageId`]}
            component={canCreatePages ? () => <PageDetail basePath={path} /> : () => <PageShow basePath={path} />}
          />
          <Route component={RouteNotFound} />
        </Switch>
      </div>
    </DocumentsProvider>
  );
};

export default DocumentsContainer;
