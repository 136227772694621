import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { generatePath, useHistory, useParams } from 'react-router';

import { Breadcrumb, LOADING_BREADCRUMB } from '@common/components/navigation-bar';
import { DocumentsRouteParams, Folder } from '../types';
import { DocumentsContextType } from '../context';
import { findRelatedObjects } from '@common/utils/recursive-data';

const BREADCRUMBS_LIMIT = 3;

interface UseBreadcrumbsProps {
  currentFolder?: Folder,
  isFetchingParents?: boolean,
  documents?: DocumentsContextType['documents'],
  onClear?: () => void,
  basePath: string,
}

export const useBreadcrumbs = ({
  currentFolder,
  isFetchingParents,
  documents,
  basePath,
  onClear,
}: UseBreadcrumbsProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { networkId, folderId, filter } = useParams<DocumentsRouteParams>();

  const onClick = useCallback((id?: string) => {
    onClear?.();
    history.push(`${generatePath(basePath, { networkId })}${id ? `/${id}` : ''}`);
  }, [basePath, history, onClear]);

  return useMemo(() => {
    if (isFetchingParents) return [{ name: LOADING_BREADCRUMB }];
    const newBreadcrumbs: Breadcrumb[] = [{
      name: networkId ? t('documents:my_documents') : t('core:tab_documents_admin'),
      onClick: () => onClick(),
    }];
    if (filter) {
      newBreadcrumbs.push({ name: t('documents:filter', { context: filter || 'all' }) });
      return newBreadcrumbs;
    }
    if (!folderId) return newBreadcrumbs;
    if (folderId === 'personal' || currentFolder?.personal_root_user_id || currentFolder?.type === 'personal_folder') {
      newBreadcrumbs.push({
        name: t('documents:personal_documents'),
        onClick: () => onClick('personal'),
      });
    }
    if (currentFolder && documents) {
      const parents = findRelatedObjects(currentFolder, documents, 'parent_folder_id');
      if (parents) {
        const isBreadcrumbsLimitExceeded = parents.length > BREADCRUMBS_LIMIT - 1;
        const breadcrumbsLimitSliceStart = parents.length - (BREADCRUMBS_LIMIT - 1);
        if (isBreadcrumbsLimitExceeded) newBreadcrumbs.push({ name: '...' });
        parents
          ?.reverse()
          .slice(isBreadcrumbsLimitExceeded ? breadcrumbsLimitSliceStart : 0)
          .forEach(({ id, name }) => {
            newBreadcrumbs.push({
              name,
              onClick: () => onClick(id),
            });
          });
      }
      if (currentFolder.type !== 'personal_folder') {
        newBreadcrumbs.push({
          name: currentFolder.name,
          onClick: () => onClick(folderId),
        });
      }
    }
    return newBreadcrumbs;
  }, [folderId, filter, isFetchingParents, currentFolder, documents, onClick, t]);
};
