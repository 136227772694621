import { TextSelection } from '@tiptap/pm/state';
import { Editor } from '@tiptap/react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import TableOfContentsItem from './table-of-contents-item';
import { combineClassNames } from '@common/utils/combineClassNames';

export interface TableOfContentsItemType {
  id: string;
  level: number;
  itemIndex: number;
  isActive: boolean;
  isScrolledOver: boolean;
  textContent: string;
}

interface TableOfContentsProps {
  editor: Editor | null;
  className?: string;
  items: TableOfContentsItemType[];
}

const TableOfContents = ({ className, items, editor }: TableOfContentsProps) => {
  const location = useLocation();
  const history = useHistory();
  const tableOfContentsRef = useRef<HTMLDivElement>(null);
  const [currentId, setCurrentId] = useState<string | null>(null);

  useEffect(() => {
    if (location.hash && editor?.view.state.selection && tableOfContentsRef.current) {
      const id = location.hash.slice(1);
      if (currentId !== id) {
        setCurrentId(id);
        setTimeout(() => scrollToElement(id), 500);
      }
    }
  }, [location.hash, tableOfContentsRef.current, editor?.view.state.selection, currentId, setCurrentId]);

  const maxHeight = useMemo(() => {
    return window.innerHeight - (tableOfContentsRef.current?.getBoundingClientRect().top || 0) - 16;
  }, [tableOfContentsRef.current, window.innerHeight]);

  const scrollToElement = (id: string) => {
    if (editor) {
      const element = editor.view.dom.querySelector(`[data-toc-id="${id}"`);
      const pos = editor.view.posAtDOM(element || document.body, 0);

      const { tr } = editor.view.state;
      tr.setSelection(new TextSelection(tr.doc.resolve(pos)));
      editor.view.dispatch(tr);
      editor.view.focus();

      window.scrollTo({
        top: (element?.getBoundingClientRect().top || 0) + window.scrollY -
          (tableOfContentsRef.current?.getBoundingClientRect().top || 0),
        behavior: 'smooth',
      });
    }
  };

  const onItemClick = (e: React.MouseEvent<HTMLAnchorElement>, id: string) => {
    e.preventDefault();
    setCurrentId(id);
    history.push(`#${id}`, { dontAskForPageLeaveConfirmation: true });
    scrollToElement(id);
  };

  return (
    <div className={`${className}`}>
      <div
        style={{ maxHeight }}
        ref={tableOfContentsRef}
        className={combineClassNames(
          'tw-fixed tw-w-[inherit] tw-box-border tw-overflow-y-auto',
          'tw-bg-white tw-px-3 tw-py-4 tw-shadow-sm tw-rounded-lg',
        )}
      >
        {items.map((item, index) => (
          <TableOfContentsItem
            {...item}
            index={index}
            onItemClick={onItemClick}
          />
        ))}
      </div>
    </div>
  );
};

export default TableOfContents;
