import React, { PropsWithChildren, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseEmoji, emojiIndex } from 'emoji-mart';
import { useForm } from 'react-hook-form';

import { Group, Row } from '@common/components/form';
import { Modal } from '@common/components/modal';
import Label from '@common/components/form/label';
import { Button } from '@common/components/button';
import { TextInputHookForm } from '@common/components/form/inputs/text';

import { EmojiInputHookForm } from '@common/components/form/inputs/emoji/emoji-input';
import { getCompleteEmoji } from '@modules/core/selectors/logged-user';

export type PageFormValues = {
  name: string;
  icon: BaseEmoji;
};

type PageFormProps = PropsWithChildren<{
  onClose: () => void;
  onUpdate: (page: PageFormValues) => void;
  onShow?: () => void;
  name: string;
  icon: string;
  show?: boolean;
}>;

const PageForm = ({
  icon, name, children, onClose, onUpdate, onShow, show,
}: PageFormProps) => {
  const { t } = useTranslation();
  const { control, handleSubmit, watch, setValue } = useForm<PageFormValues>({
    defaultValues: {
      // Fix me: getCompleteEmoji should be refactored
      icon: icon ? getCompleteEmoji(icon, emojiIndex.emojis) : null,
      name,
    },
  });
  const values = watch();

  useEffect(() => {
    // Fix me: getCompleteEmoji should be refactored
    setValue('icon', icon ? getCompleteEmoji(icon, emojiIndex.emojis) : null);
    setValue('name', name);
  }, [icon, name]);

  return (
    <Modal
      list
      size="small"
      // So the emoji picker search input can get focus
      enforceFocus={false}
      onClose={onClose}
      onShow={onShow}
      show={show}
      content={(
        <Group>
          <Label text="Icon" />
          <Row>
            <EmojiInputHookForm
              name="icon"
              control={control}
              emoji={values.icon}
              className="tw-cursor-pointer"
              size={32}
            />
          </Row>
          <Label text={t('documents:name')} />
          <Row>
            <TextInputHookForm
              name="name"
              control={control}
              placeholder={t('documents:placeholder_title')}
            />
          </Row>
        </Group>
      )}
      footer={() => (
        <Button
          type="primary"
          onClick={() => handleSubmit(onUpdate)()}
        >
          {t('documents:update')}
        </Button>
      )}
    >
      {children}
    </Modal>
  );
};

export default PageForm;
