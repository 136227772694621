import React, { useRef, useState } from 'react';
import { Editor } from '@tiptap/react';

import { Button } from '@common/components/button';
import ToolbarHeadersDropdown from './toolbar-headers-dropdown';
import ToolbarLinkModal from './toolbar-link-modal';
import ToolbarFileModal, { EditorFileType } from './toolbar-file-modal';
import ToolbarSeparator from './toolbar-separator';
import { IconTypes } from '@common/components/icon';
import { combineClassNames } from '@common/utils/combineClassNames';

type ToolbarType = {
  editor: Editor | null;
  sticky?: boolean;
  editable?: boolean;
  hideActions?: IconTypes[];
  sidebarActive?: boolean;
  setSidebarActive?: (active: boolean) => void;
  onPrint?: (html: string) => void;
};

const Toolbar = ({
  editor, sticky = false, editable = false, sidebarActive, hideActions, setSidebarActive, onPrint,
}: ToolbarType) => {
  const [showLinkModal, setShowLinkModal] = useState(false);
  const [showFileModal, setShowFileModal] = useState<EditorFileType | null>(null);
  const selection = window.getSelection()?.toString() || '';
  const toolbarRef = useRef<HTMLDivElement>(null);

  return (
    <>
      {showLinkModal && (
        <ToolbarLinkModal
          editor={editor}
          text={selection}
          onClose={() => setShowLinkModal(false)}
        />
      )}
      {!!showFileModal && (
        <ToolbarFileModal
          editor={editor}
          type={showFileModal}
          onClose={() => setShowFileModal(null)}
        />
      )}
      <div
        ref={toolbarRef}
        className={combineClassNames(
          {
            'tw-sticky tw-z-5': sticky,
          },
          'tw-w-full tw-overflow-visible tw-py-2',
          'tw-px-4 tw-box-border tw-bg-white tw-p-1 tw-shadow-sm',
        )}
        style={{
          top: sticky ? toolbarRef.current?.getBoundingClientRect().top || 0 : undefined,
        }}
        aria-label="Formatting options"
      >
        <div className={combineClassNames(
          'tw-max-w-[calc(var(--container-max-width)-(var(--padding-base)*2))]',
          'tw-flex tw-items-center tw-mx-auto',
        )}
        >
          {editable && (
            <>
              <div aria-label="Text formatting">
                <Button
                  icon="font_bold"
                  type="toolbar"
                  active={!!editor?.isActive('bold')}
                  onClick={() => editor?.chain().focus().toggleBold().run()}
                />
                <Button
                  icon="font_italic"
                  type="toolbar"
                  active={!!editor?.isActive('italic')}
                  onClick={() => editor?.chain().focus().toggleItalic().run()}
                />
                <Button
                  icon="text_underline"
                  type="toolbar"
                  active={!!editor?.isActive('underline')}
                  onClick={() => editor?.chain().focus().toggleUnderline().run()}
                />
                <Button
                  icon="text_strikethrough"
                  type="toolbar"
                  active={!!editor?.isActive('strike')}
                  onClick={() => editor?.chain().focus().toggleStrike().run()}
                />
              </div>
              <ToolbarSeparator />
              <div aria-label="Insert lists">
                <Button
                  icon="text_numbered_list"
                  type="toolbar"
                  active={!!editor?.isActive('orderedList')}
                  onClick={() => editor?.chain().focus().toggleOrderedList().run()}
                />
                <Button
                  icon="text_bullet_list"
                  type="toolbar"
                  active={!!editor?.isActive('bulletList')}
                  onClick={() => editor?.chain().focus().toggleBulletList().run()}
                />
              </div>
              <ToolbarSeparator />
              <div className="tw-flex" aria-label="Insert links">
                <Button
                  icon="text_link"
                  type="toolbar"
                  disabled={!selection.length}
                  active={!!editor?.isActive('link')}
                  onClick={() => setShowLinkModal(true)}
                />
                <ToolbarHeadersDropdown editor={editor} />
              </div>
              <ToolbarSeparator />
              <div aria-label="Insert tables">
                <Button
                  icon="insert_table"
                  type="toolbar"
                  onClick={() => editor?.chain().focus().insertTable({ rows: 2, cols: 2, withHeaderRow: false }).run()}
                />
              </div>
              <ToolbarSeparator />
              <div aria-label="Insert videos or images">
                <Button
                  icon="insert_image"
                  type="toolbar"
                  onClick={() => setShowFileModal(EditorFileType.IMAGE)}
                />
                <Button
                  icon="insert_video"
                  type="toolbar"
                  onClick={() => setShowFileModal(EditorFileType.VIDEO)}
                />
              </div>
              <ToolbarSeparator />
              <div aria-label="History">
                <Button
                  icon="undo"
                  type="toolbar"
                  disabled={!editor?.can().undo()}
                  onClick={() => editor?.chain().focus().undo().run()}
                />
                <Button
                  icon="redo"
                  type="toolbar"
                  disabled={!editor?.can().redo()}
                  onClick={() => editor?.chain().focus().redo().run()}
                />
              </div>
            </>
          )}
          <div aria-label="Sidebar" className="tw-ml-auto">
            {onPrint && (
              <Button
                icon="print"
                type="toolbar"
                onClick={() => onPrint(editor?.getHTML() || '')}
              />
            )}
            {!!setSidebarActive && !hideActions?.includes('sidebar') && (
              <Button
                icon="sidebar"
                type="toolbar"
                active={sidebarActive}
                onClick={() => setSidebarActive(!sidebarActive)}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Toolbar;
